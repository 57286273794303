/* https://css-tricks.com/custom-scrollbars-in-webkit/ */
/* https://stackoverflow.com/questions/40512026/no-arrow-buttons-in-scrollbar-after-writing-webkit-css */

.darkmodeScroll::-webkit-scrollbar {
	width: 1rem;
	height: 1rem;
}

/* background of bar*/
.darkmodeScroll::-webkit-scrollbar-track-piece {
	background-color: #202020;
}

/* background is the actual scroll thumb*/
.darkmodeScroll::-webkit-scrollbar-thumb {
	background-color: #333;
	border-top: 2px solid #666;
	border-bottom: 2px solid #666;
}

.darkmodeScroll::-webkit-scrollbar-thumb:hover {
	background-color: #444;
}

.darkmodeScroll::-webkit-scrollbar-thumb:active {
	background-color: #555;
}

/* Buttons */
.darkmodeScroll::-webkit-scrollbar-button {
	background-size: 1rem;
}

.darkmodeScroll::-webkit-scrollbar-button:single-button {
	background-color: #333;
	display: block;
	background-repeat: no-repeat;
}

/* Up */
.darkmodeScroll::-webkit-scrollbar-button:single-button:vertical:decrement {
	height: 22px;
	width: 22px;
	background-position: center 6px;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(210, 210, 210)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.darkmodeScroll::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(3, 88, 151)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.darkmodeScroll::-webkit-scrollbar-button:single-button:vertical:decrement:active {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(7, 127, 216)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
.darkmodeScroll::-webkit-scrollbar-button:single-button:vertical:increment {
	height: 22px;
	width: 22px;
	background-position: center 7px;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(210, 210, 210)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.darkmodeScroll::-webkit-scrollbar-button:single-button:vertical:increment:hover {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(3, 88, 151)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.darkmodeScroll::-webkit-scrollbar-button:single-button:vertical:increment:active {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(7, 127, 216)'><polygon points='0,0 100,0 50,50'/></svg>");
}
