.lightmodeScroll::-webkit-scrollbar {
	width: 1rem;
	height: 1rem;
}

/* background of bar*/
.lightmodeScroll::-webkit-scrollbar-track-piece {
	background-color: #f1f1f1;
}

/* background is the actual scroll thumb*/
.lightmodeScroll::-webkit-scrollbar-thumb {
	background-color: #bbb;
	border-top: 2px solid #777;
	border-bottom: 2px solid #777;
}

.lightmodeScroll::-webkit-scrollbar-thumb:hover {
	background-color: #aaa;
}

.lightmodeScroll::-webkit-scrollbar-thumb:active {
	background-color: #999;
}

/* Buttons */
.lightmodeScroll::-webkit-scrollbar-button {
	background-size: 1rem;
}

.lightmodeScroll::-webkit-scrollbar-button:single-button {
	background-color: #999;
	display: block;
	background-repeat: no-repeat;
}

/* Up */
.lightmodeScroll::-webkit-scrollbar-button:single-button:vertical:decrement {
	height: 22px;
	width: 22px;
	background-position: center 6px;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 255, 255)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.lightmodeScroll::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(3, 88, 151)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.lightmodeScroll::-webkit-scrollbar-button:single-button:vertical:decrement:active {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(7, 127, 216)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
.lightmodeScroll::-webkit-scrollbar-button:single-button:vertical:increment {
	height: 22px;
	width: 22px;
	background-position: center 7px;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 255, 255)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.lightmodeScroll::-webkit-scrollbar-button:single-button:vertical:increment:hover {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(3, 88, 151)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.lightmodeScroll::-webkit-scrollbar-button:single-button:vertical:increment:active {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(7, 127, 216)'><polygon points='0,0 100,0 50,50'/></svg>");
}
